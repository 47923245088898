/**
 * How To Use
 * The bold text should be inside <span>{text}<span> tag
 * The underlined text should be inside <span class="highlighted-text">{text}</text>
 */
import React from 'react';

//External Components
import { Heading } from '@thepuzzlers/pieces';

//Main Components
export const HilightedHeading = ({ headline, sx, ...props }) => {
  //Markup
  return (
    <Heading
      dangerouslySetInnerHTML={{
        __html: headline
      }}
      {...props}
      sx={{
        ...sx,
        position: 'relative',
        zIndex: 1,
        '& .highlighted-text': {
          color: 'green'
        }
      }}
    />
  );
};
