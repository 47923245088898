import { gsap } from 'gsap/all';

// Slide App Screens to position on scroll animation
// export const slideUpAppScreens = (wrapperRef) => {
//   const appScreens = [...wrapperRef.current.children];

//   let tl = gsap.timeline({
//     scrollTrigger: {
//       trigger: 'footer',
//       start: 'top bottom',
//       endTrigger: wrapperRef.current,
//       end: 'bottom 80%',
//       scrub: 0.5
//     }
//   });

//   tl.fromTo(
//     appScreens,
//     { yPercent: 60 },
//     { yPercent: 0, stagger: 0.1, ease: 'Power1.easeOut' }
//   );

//   const killAnimation = () => {
//     tl.scrollTrigger.kill(true);
//     tl.pause(0).kill(true);
//   };

//   return killAnimation;
// };

export const ctaCardAnimation = (timelineRef, ctaCardRef) => {
  const elements = [...ctaCardRef.current.children[0].children];
  const headline = elements[1];
  const appScreens = elements[5].children[0].children;

  timelineRef.current = gsap.timeline({
    scrollTrigger: {
      trigger: 'footer',
      start: 'top 70%',
      end: 'bottom top'
    },
    defaults: {
      duration: 1.5,
      ease: 'expo.out',
      autoAlpha: 0
    }
  });

  timelineRef.current
    .from(appScreens[1], {
      y: '100%'
    })
    .from(
      appScreens[0],
      {
        y: '100%',
        duration: 1.2
      },
      '<0.3'
    )
    .from(
      headline,
      {
        y: '40%',
        duration: 1.2
      },
      '<0.3'
    );
  const kill = () => {
    timelineRef.current?.kill();
  };
  return {
    kill
  };
};

// Arrow animation default and while hovering button with Framer Motion
export const arrowMotion = {
  animate: {
    scaleX: 0.8,
    x: -10,
    originX: 0,
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 0.5,
      ease: [0.64, -0.01, 0.99, 0.73]
    }
  }
  // hover: {
  // x: [0, -10, 0],
  //   transition: {
  //     default: {
  //       type: 'tween',
  //       ease: 'easeInOut',
  //       duration: 2,
  //       loop: Infinity
  //     }
  //   }
  // }
};
