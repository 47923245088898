import React from 'react';
import { AnimatePresence } from 'framer-motion';

// External Components
import { Overlay, GridWrapper } from '@thepuzzlers/pieces';

// Local Components
import { FullGridSpacer } from 'components';

// Self components
import {
  GreenBackground,
  WhiteBackground,
  CloseButton,
  Form,
  InfoWrapper
} from './components';

// Animations
import { revealOverlay } from './animations';

// Main Components
export const JoinNowOverlay = ({
  isOpen,
  handleClose,
  handleOnSubmit,
  headline,
  socialLinks,
  subHeadline,
  form,
  agreementText,
  messages,
  formState
}) => {
  const data = {
    headline,
    socialLinks,
    subHeadline,
    form,
    agreementText,
    messages,
    handleOnSubmit,
    formState
  };

  // Markup
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          id="join-now-overlay"
          handleClose={handleClose}
          containerSx={{
            bg: 'purple',
            overflowY: 'scroll',
            /* Hide scrollbar for Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for IE and Edge */
            msOverflowStyle: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
          shouldCloseOnBackdropClick={true} // true is the default
          shouldCloseOnEscapePress={true} // true is the default
        >
          <OverlayWrapper>
            <GreenBackground />
            <Spacer position={0} />
            <CloseButton onClick={handleClose} />
            <InfoWrapper data={data} />
            <Spacer position={1} />
            <WhiteBackground />
            <Spacer position={2} />
            <Form data={data} />
            <Spacer position={3} />
          </OverlayWrapper>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

// Elements

const OverlayWrapper = ({ children }) => (
  <GridWrapper
    className="main-grid"
    // Animation values
    animate="open"
    initial="closed"
    exit="closed"
    variants={revealOverlay}
    // Styles
    sx={{
      alignContent: ['stretch', 'unset', 'unset', 'unset', 'unset', 'unset'],
      height: '100%'
    }}>
    {/** later used to alignItems center */}
    <GridWrapper
      className="inner-grid"
      variant="inside.autoColumns"
      sx={{
        alignSelf: ['unset', 'center', 'center', 'unset', 'center', 'center'],
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
        gridTemplateRows: [
          'repeat(7, auto)',
          'repeat(7, auto)',
          'repeat(7, auto)',
          'repeat(2, auto) 1fr',
          'repeat(3, auto)',
          'repeat(3, auto)'
        ]
      }}>
      {children}
    </GridWrapper>
  </GridWrapper>
);

const Spacer = ({ position }) => {
  const styles = [
    {
      gridRow: 1,
      mt: ['6.27%', '6.2%', '4.2%', '4%', '2.6%', '1.7%']
    },
    {
      gridRow: [4, 4, 4, 3, 4, 4],
      mt: ['12.86%', '5.4%', '6%', '1%', '3.3%', '2.2%']
    },
    {
      display: ['block', 'block', 'block', 'none', 'none', 'none'],
      gridRow: 5,
      mt: ['11.91%', '10.5%', '8.3%']
    },
    {
      display: ['block', 'block', 'block', 'none', 'none', 'none'],
      gridRow: 7,
      mt: ['15.36%', '8.7%', '7.9%']
    }
  ];

  return <FullGridSpacer sx={{ ...styles[position] }} />;
};
