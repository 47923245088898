import React from 'react';

// pieces
import { Box, Image } from '@thepuzzlers/pieces';

export const Vector = ({ vectorRef, svg, alt, sx, imageProps, ...props }) => {
  return (
    <Box ref={vectorRef} sx={{ display: 'flex', ...sx }} {...props}>
      <Image
        src={svg}
        alt={alt}
        sx={{ maxHeight: '100%', maxWidth: '100%' }}
        {...imageProps}
      />
    </Box>
  );
};
