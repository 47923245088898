const linkDefault = {
  cursor: 'pointer'
};

const buttonDefault = {
  border: 'none',
  borderRadius: 'button',
  cursor: 'pointer',
  transition: '0.2s ease-out',
  whiteSpace: 'nowrap',
  '&:hover': {
    transform: 'scale(1.05)'
  }
};

const links = {
  hyperLink: {
    ...linkDefault,
    variant: 'typography.hyperLink.normal',
    textDecoration: 'underline'
    // ':hover,:focus,active': {
    // }
  },
  navigation: {
    ...linkDefault,
    variant: 'typography.navigationLink.medium'
    // ':hover,:focus,&.active': {
    // }
  },
  footer: {
    ...linkDefault,
    variant: 'links.navigation'
    // ':hover,:focus,&.active': {
    // }
  },
  iconLink: {
    ...linkDefault,
    bg: 'transparent'
  },
  languageSwitchLink: {
    ...linkDefault,
    variant: 'typography.languageSwitchLink.medium',
    color: 'text'
  }
};

const buttons = {
  primary: {
    ...buttonDefault,
    variant: 'typography.button.medium',
    py: [
      10,
      14,
      12,
      10,
      12,
      'calc(12px + ((100vw - 1400px) * 12 / 2.5 / 1400))'
    ],
    px: [
      20,
      28,
      30,
      20,
      30,
      'calc(30px + ((100vw - 1400px) * 30 / 2.5 / 1400))'
    ],
    bg: 'purple',
    color: 'white',
    // '&:hover': {
    //   transform: 'scale(1.2)'
    // }
  },
  secondary: {
    ...buttonDefault,
    variant: 'typography.button.medium',
    bg: 'green',
    color: 'purple',
    p: [
      '8px 16px',
      '10px 20px',
      '12px 30px',
      '10px 20px',
      '12px 30px',
      '12px 30px'
    ],
    width: 'min-content'
  },
  tertiary: {
    ...buttonDefault,
    variant: 'typography.button.medium',
    bg: 'purple',
    color: 'white',
    p: [
      '10px 20px',
      '14px 22px',
      '12px 30px',
      '10px 20px',
      '12px 30px',
      '12px 30px'
    ],
    width: 'min-content'
  },
  emptyBase: {
    border: 'none',
    cursor: 'pointer',
    bg: 'transparent',
    p: 0,
    m: 0
  }
};

const cards = {
  // primary: {
  // }
};

export { links, buttons, cards };
