import React from 'react';

// External Components
import { Heading, Paragraph } from '@thepuzzlers/pieces';

// Animations
import { revealHeadline, revealText } from '../animations';

export const Headline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      type="h2-300-125"
      variant="bold"
      // Animation values
      animate="open"
      initial="closed"
      exit="closed"
      variants={revealHeadline}
      // Styles
      sx={{
        gridRow: 1,
        gridColumn: [
          '1/span 9',
          '1/span 9',
          '1/span 12',
          '1/span 9',
          '1/span 7',
          '1/span 7'
        ],
        width: ['100%', '100%', '100%', '92%']
      }}>
      {headline}
    </Heading>
  );
};

export const SubHeadline = ({ subHeadline }) => (
  <Paragraph
    className="join-now-overlay__subHeading"
    type="p-500-150-f"
    variant="medium"
    // Animation values
    animate="open"
    initial="closed"
    exit="closed"
    variants={revealText}
    // Styles
    sx={{
      alignSelf: ['start', 'start', 'start', 'start', 'start', 'center'],
      gridRow: [5, 5, 5, 6, 7, 8],
      gridColumn: [
        '1/span 8',
        '1/span 5',
        '1/span 7',
        '1/span 7',
        '1/span 5',
        '1/span 5'
      ]
    }}>
    {subHeadline}
  </Paragraph>
);
