import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useNavigationData = () => {
  const { locale } = usePageContext();
  const data = useStaticQuery(graphql`
    query {
      en: navigationDataJson {
        links {
          title
          to
          icon {
            publicURL
          }
        }
        button
      }
      de: deNavigationDataJson {
        links {
          title
          to
          icon {
            publicURL
          }
        }
        button
      }
    }
  `);

  return data[locale];
};
