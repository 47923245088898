// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  background: '#FFFFFF',
  black: '#191716',
  purple: '#300D82',
  white: '#F6F8FF',
  green: '#adf24a'
};

const textColors = {
  text: base.black,
  heading: base.black,
  secondary: '#8A827E'
};

export const colors = {
  // defaults
  ...base,
  ...textColors
};
