import React from 'react';

//Local Components
import { useOverlayData } from './useOverlayData';

// formStates
const WAITING_FOR_SUBMIT = 'waiting';
const SUBMITTING = 'submitting';
const ERROR = 'error';
const SUCCESS = 'success';

export const useJoinNowOverlay = () => {
  const { headline, subHeadline, socialLinks, form, agreementText, messages } =
    useOverlayData();
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [formState, setFormState] = React.useState(WAITING_FOR_SUBMIT);

  const handleOpenOverlay = () => setShowOverlay(true);
  const handleCloseOverlay = () => setShowOverlay(false);

  // Close overlay and remove message after 2sec
  const handleBackToDefault = () => {
    setTimeout(() => {
      handleCloseOverlay();
      setFormState(SUBMITTING);
    }, 3000);
  };

  const handleServerResponse = (ok, form) => {
    if (ok) {
      setFormState(SUCCESS);
      // Only rest form, if sending was successful
      form.reset();
    } else {
      setFormState(ERROR);
    }
  };

  // Handle responses on form submit
  const handleOnSubmit = (e) => {
    e.preventDefault();
    // return console.log('Form submitted', e.target);
    setFormState(SUBMITTING);
    const form = e.target;
    const formData = new FormData(form);

    fetch('https://getform.io/f/cc2af22b-ff9e-4ae0-bccd-061c707131e1', {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        // show loader a bit longer to avoid loading flash
        setTimeout(() => {
          if (response.ok) {
            handleServerResponse(true, form);
          } else {
            // todo
            handleServerResponse(false, form);
          }
        }, 400);
      })
      .catch(() => {
        setTimeout(() => {
          handleServerResponse(false, form);
        }, 400);
      });
  };

  const overlayProps = {
    isOpen: showOverlay,
    handleClose: handleCloseOverlay,
    handleOnSubmit,
    headline,
    socialLinks,
    subHeadline,
    form,
    agreementText,
    messages,
    formState
  };

  const overlayControl = {
    handleOpenOverlay,
    handleCloseOverlay
  };

  return [overlayProps, overlayControl];
};
