export const revealOverlay = {
  open: { y: 0, transition: { duration: 0.5, ease: 'easeOut' } },
  closed: {
    y: '100%',
    transition: { delay: 0.2, duration: 0.3, ease: 'easeIn' }
  }
};

export const revealHeadline = {
  open: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.5, duration: 0.7, ease: 'easeOut' }
  },
  closed: {
    opacity: 0,
    y: 60,
    transition: { duration: 0.2, ease: 'easeIn' }
  }
};

export const revealDecoration = {
  open: {
    scale: 1,
    transition: { delay: 0.6, duration: 0.7, ease: 'easeOut' }
  },
  closed: {
    scale: 0,
    transition: { duration: 0.2, ease: 'easeIn' }
  }
};

export const revealSocials = {
  open: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3
    }
  },
  closed: {
    opacity: 0
  }
};

export const revealSocialItem = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.7,
      duration: 0.7,
      ease: 'easeOut'
    }
  },
  closed: { opacity: 0, y: 50, transition: { duration: 0.2, ease: 'easeIn' } }
};

export const revealText = {
  open: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.8, duration: 0.7, ease: 'easeOut' }
  },
  closed: {
    opacity: 0,
    y: 60,
    transition: { duration: 0.2, ease: 'easeIn' }
  }
};

// Reveal success or error message on submit 
export const showMessage = {
  hide: {
    opacity: 0,
    height: 0,
    transition: {
      type: 'spring',
      duration: 0.3,
      ease: 'circIn'
    }
  },
  show: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'spring',
      duration: 0.3,
      ease: 'circIn'
    }
  }
};