import React from 'react';

// pieces
import { Box } from '@thepuzzlers/pieces';

/* ------------------------------------------------
----- This spacer needs to be passed how many ----- 
------------ columns it will expand to ------------
------------------------------------------------ */

export const Spacer = ({ sx }) => {
  return <Box className="spacer" sx={{ ...sx }} />;
};
