export const typographyConfig = {
  // h3, h4 and paragraph are left in for the legal pages
  h3: {
    fontSizes: [33.6, 28.8, 37.6, 37.6, 44],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  h4: {
    fontSizes: [19.5, 24.5, 25.5, 18, 26.5, 28.5],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  paragraph: {
    fontSizes: [14.97, 15.29, 16.27, 14.1, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: 1.75
  },
  // Landing Page font settings start here
  'h1-500-125': {
    fontSizes: [24.33, 25.18, 30.63, 22.56, 29.7, 35.59],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h1-500-125-a': {
    fontSizes: [24.33, 25.18, 38.29, 22.56, 29.7, 35.59],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h2-300-125': {
    fontSizes: [26.2, 26.98, 38.29, 26.32, 29.7, 39.55],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h2-300-125-b': {
    fontSizes: [24.33, 26.98, 36.37, 26.32, 29.7, 39.55],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h2-300-125-c': {
    fontSizes: [26.2, 26.98, 38.29, 26.32, 29.7, 55.37],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h2-300-125-d': {
    fontSizes: [22.46, 26.98, 36.37, 26.32, 29.7, 39.55],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  'h2-300-125-e': {
    fontSizes: [26.2, 26.98, 36.37, 26.32, 29.7, 39.55],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h2-400-125': {
    fontSizes: [26.2, 28.78, 40.2, 28.2, 38.98, 47.46],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h2-400-125-a': {
    fontSizes: [24.33, 28.78, 40.2, 28.2, 38.98, 47.46],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h2-500-100': {
    fontSizes: [26.2, 32.37, 42.11, 30.08, 40.83, 55.37],
    fontFamily: 'primary',
    lineHeight: 1.1
  },
  'h2-500-110': {
    fontSizes: [28.07, 32.37, 42.11, 30.08, 40.83, 55.37],
    fontFamily: 'primary',
    lineHeight: 1.1
  },
  'h3-500-125': {
    fontSizes: [22.46, 21.58, 22.97, 20.68, 22.27, 39.55],
    fontFamily: 'primary',
    lineHeight: 1.35
  },
  'h3-500-125-b': {
    fontSizes: [20.59, 20.68, 22.97, 18.8, 20.42, 23.73],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h3-500-125-c': {
    fontSizes: [22.46, 21.58, 22.97, 16.92, 22.27, 27.68],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h3-500-125-d': {
    fontSizes: [18.71, 19.78, 21.06, 18.8, 22.27, 27.68],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h3-500-125-e': {
    fontSizes: [20.59, 23.38, 24.89, 20.68, 24.13, 27.68],
    fontFamily: 'primary',
    lineHeight: 1.35,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h3-500-125-f': {
    fontSizes: [20.59, 21.58, 22.97, 14.1, 20.42, 23.73],
    fontFamily: 'primary',
    lineHeight: [1.35, 1.35, 1.35, 1.25, 1.25, 1.25],
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h3-500-125-g': {
    fontSizes: [16.84, 17.99, 19.14, 15.04, 18.56, 21.75],
    fontFamily: 'primary',
    lineHeight: 1.25,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h4-300-135': {
    fontSizes: [14.97, 17.09, 19.14, 15.04, 16.7, 18.79],
    fontFamily: 'primary',
    lineHeight: 1.35
  },
  'h4-300-135-a': {
    fontSizes: [14.97, 14.39, 15.31, 14.1, 16.7, 17.8],
    fontFamily: 'primary',
    lineHeight: 1.35
  },
  'h4-500-100': {
    fontSizes: [18.71, 17.99, 19.14, 18.8, 18.56, 19.77],
    fontFamily: 'primary',
    lineHeight: 1
  },
  'h4-500-125': {
    fontSizes: [18.71, 17.99, 21.06, 17.86, 20.42, 21.75],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  'p-300-100': {
    fontSizes: [12.16, 12.59, 13.4, 12.22, 12.99, 13.84],
    fontFamily: 'body',
    lineHeight: 1
  },
  'p-300-100-b': {
    fontSizes: [13.1, 13.49, 14.36, 13.16, 13.92, 14.83],
    fontFamily: 'body',
    lineHeight: 1
  },
  'p-300-125': {
    fontSizes: [13.1, 12.59, 14.36, 13.16, 13.92, 14.83],
    fontFamily: 'body',
    lineHeight: 1.25
  },
  'p-300-150': {
    fontSizes: [12.16, 12.59, 13.4, 12.22, 12.99, 13.84],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-300-150-a': {
    fontSizes: [13.1, 13.49, 14.36, 13.16, 13.92, 14.83],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-300-150-b': {
    fontSizes: [13.1, 13.49, 13.4, 11.28, 12.99, 14.83],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-500-135': {
    fontSizes: [14.97, 14.39, 17.23, 14.1, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.35,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-500-135-b': {
    fontSizes: [14.97, 14.39, 16.27, 14.1, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.35,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-500-135-c': {
    fontSizes: [14.97, 15.29, 16.27, 14.1, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.35
  },
  'p-500-150-a': {
    fontSizes: [13.1, 14.39, 15.31, 14.1, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.5,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-500-150-b': {
    fontSizes: [14.04, 14.39, 15.31, 14.1, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.5,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-500-150-c': {
    fontSizes: [14.97, 14.39, 15.31, 14.1, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.5,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-500-150-d': {
    fontSizes: [14.04, 14.39, 15.31, 13.16, 14.85, 17.8],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-500-150-e': {
    fontSizes: [15.91, 15.29, 17.23, 14.1, 16.7, 17.8],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-500-150-f': {
    fontSizes: [14.97, 15.29, 16.27, 14.1, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-600-135': {
    fontSizes: [14.97, 14.39, 17.23, 14.1, 16.7, 17.8],
    fontFamily: 'primary',
    lineHeight: 1.35
  },

  'p-600-135-b': {
    fontSizes: [14.97, 17.09, 17.23, 13.16, 16.7, 17.8],
    fontFamily: 'primary',
    lineHeight: 1.35
  },
  'p-600-135-c': {
    fontSizes: [14.97, 17.09, 17.23, 14.1, 16.7, 17.8],
    fontFamily: 'primary',
    lineHeight: 1.35
  },
  'p-600-150': {
    fontSizes: [18.71, 17.09, 22.97, 18.8, 22.27, 23.73],
    fontFamily: 'primary',
    lineHeight: [1.25, 1.35, 1.1, 1.1, 1.1, 1.1]
  },
  'p-700-150': {
    fontSizes: [14.04, 15.29, 17.23, 14.1, 16.7, 19.77],
    fontFamily: 'body',
    lineHeight: 1.5,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-800-175': {
    fontSizes: [16.84, 14.39, 19.14, 13.16, 18.56, 21.75],
    fontFamily: 'primary',
    lineHeight: 1.75,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'p-900-125': {
    fontSizes: [18.71, 19.78, 22.97, 18.8, 22.27, 31.64],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  label: {
    fontSizes: [13.1, 12.59, 13.4, 11.28, 12.99, 13.84],
    fontFamily: 'body',
    lineHeight: 1
  },
  input: {
    fontSizes: [14.97, 14.39, 15.31, 13.16, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1
  },
  button: {
    fontSizes: [14.97, 14.39, 16.27, 14.1, 15.78, 17.8],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  caption: {
    fontSizes: [14.97, 19.78, 19.14, 15.04, 18.56, 23.73],
    fontFamily: 'primary',
    lineHeight: 1,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  languageSwitchLink: {
    fontSizes: [14.97, 14.39, 15.31, 15.04, 14.85, 15.82],
    fontFamily: 'body'
  },
  navigationLink: {
    fontSizes: [0, 0, 0, 0, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.5
  }
};
