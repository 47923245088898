import React from 'react';

// Data
import { useNavigationData } from './useNavigationData';

// Pieces components
import { GridWrapper } from '@thepuzzlers/pieces';

// Local Components
import { LanguageSwitch } from 'components';
import { JoinNowOverlay, useJoinNowOverlay } from 'sections/overlay';

// Self components
import { Logo } from './Logo';
import { LinksWrapper } from './LinksWrapper';
import { NavButton } from './NavButton';

// Animations
import { revealNav } from './animations';

export const Navigation = () => {
  const { links, button } = useNavigationData();
  const [overlayProps, overlayControl] = useJoinNowOverlay();
  const [isAnimationComplete, setIsAnimationComplete] = React.useState(false);
  const navRef = React.useRef(null);
  React.useEffect(() => {
    const killAnimation = revealNav(navRef, setIsAnimationComplete);

    return killAnimation;
  }, []);

  return (
    // Markup
    <NavWrapper designatedRef={navRef}>
      <Logo />
      <LinksWrapper links={links} />
      <NavButton button={button} onClick={overlayControl.handleOpenOverlay} />
      <LanguageSwitch isNavAnimationComplete={isAnimationComplete} />
      <JoinNowOverlay {...overlayProps} />
    </NavWrapper>
  );
};

// Elements

const NavWrapper = ({ children, designatedRef }) => (
  <GridWrapper
    ref={designatedRef}
    as="nav"
    sx={{
      alignItems: 'center',
      bg: [
        'white',
        'white',
        'white',
        'transparent',
        'transparent',
        'transparent'
      ],
      py: ['2.51%', '2.33%', '2.26%', '2.03%', '1.6%', '0.97%'],
      position: [
        'static',
        'static',
        'static',
        'absolute',
        'absolute',
        'absolute'
      ],
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 3
    }}>
    {children}
  </GridWrapper>
);
