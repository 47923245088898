import React from 'react';

// External Components
import {
  GridWrapper,
  Box,
  Label,
  Input,
  Button,
  Heading,
  Paragraph
} from '@thepuzzlers/pieces';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

// Local Components
import { Spacer, ButtonPrimary } from 'components';

// Animations
import { showMessage } from '../animations';

// formStates
const ERROR = 'error';
const SUCCESS = 'success';
const SUBMITTING = 'submitting';

export const Form = ({
  data: {
    form: { inputs, submitButtonText },
    agreementText,
    messages,
    handleOnSubmit,
    formState
  }
}) => {
  return (
    // Markup
    <FormWrapper handleSubmit={handleOnSubmit}>
      <InputField
        id={`input-field-${inputs[0].name}`}
        {...inputs[0]}
        row={1}
        required
      />
      <GridSpacer position={0} row={2} />
      <InputField
        id={`input-field-${inputs[1].name}`}
        {...inputs[1]}
        row={3}
        required
      />
      <GridSpacer position={0} row={4} />
      <InputField
        id={`input-field-${inputs[2].name}`}
        {...inputs[2]}
        row={5}
        required
      />
      <GridSpacer position={1} row={6} />
      <SubmitButton disabled={formState === SUBMITTING}>
        <Box
          as="span"
          sx={{ visibility: formState === SUBMITTING && 'hidden' }}>
          {submitButtonText}
        </Box>
        <Box
          as="span"
          f
          sx={{
            visibility: formState !== SUBMITTING && 'hidden',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)'
          }}>
          . . .
        </Box>
      </SubmitButton>

      {formState === SUCCESS || formState === ERROR ? (
        <SubmitMessage
          message={formState === SUCCESS ? messages.success : messages.error}
          formState={formState}
        />
      ) : (
        <AgreementTextForm agreementText={agreementText} />
      )}
    </FormWrapper>
  );
};

// Elements

const FormWrapper = ({ children, handleSubmit }) => (
  <GridWrapper
    onSubmit={handleSubmit}
    as="form"
    autoComplete="off"
    className="footer__from-wrapper"
    variant="inside.autoColumns"
    sx={{
      alignSelf: 'start',
      gridRow: [6, 6, 6, 2, 3, 3],
      gridColumn: [
        '1/13',
        '2/span 10',
        '6/span 14',
        '14/span 9',
        '14/span 7',
        '14/span 7'
      ]
    }}>
    {children}
  </GridWrapper>
);

const InputField = ({ id, type, name, placeholder, row, required }) => (
  <Box
    className="input-field"
    sx={{
      gridRow: row,
      gridColumn: [
        '1/span 12',
        '1/span 10',
        '1/span 14',
        '1/span 9',
        '1/span 7',
        '1/span 7'
      ]
    }}>
    <Label htmlFor={id} variant="forms.label">
      {name}
    </Label>
    <Input
      id={id}
      type={type}
      required={required}
      name={name}
      placeholder={placeholder}
    />
  </Box>
);

const SubmitButton = ({ children, disabled }) => (
  <ButtonPrimary
    disabled={disabled}
    variant="tertiary"
    type="submit"
    sx={{
      gridRow: 7,
      gridColumnStart: 1,
      justifySelf: 'start',
      alignSelf: 'start'
    }}>
    {children}
  </ButtonPrimary>
);

const AgreementTextForm = ({ agreementText }) => {
  const { locale } = usePageContext();
  const isGermanyLanguage = locale === 'de';
  return (
    <Paragraph
      className="footer__agreement-text"
      type="p-300-150-a"
      sx={{
        gridColumn: isGermanyLanguage
          ? [
              '1/span 10',
              '5/span 6',
              '8/span 7',
              '1/span 9',
              '1/span 7',
              '1/span 7'
            ]
          : [
              '1/span 10',
              '4/span 7',
              '6/span 9',
              '1/span 9',
              '1/span 7',
              '1/span 7'
            ],
        gridRow: [8, 7, 7, 8, 8, 8],
        opacity: 0.5,
        mt: ['8.8%', 0, 0, '8.4%', '11.4%', '10.4%']
      }}>
      {agreementText}
    </Paragraph>
  );
};

const SubmitMessage = ({ message, formState }) => (
  <Box
    initial="hide"
    animate="show"
    variants={formState === SUCCESS || formState === ERROR ? showMessage : null}
    sx={{
      gridColumn: [
        '1/span 8',
        '4/span 7',
        '6/span 9',
        '1/span 9',
        '1/span 7',
        '1/span 7'
      ],
      gridRow: 9,
      mt: ['8%', '9%', '10%', '8.4%', '11.4%', '8%'],
      // Animation values
      height: 0,
      overflow: 'hidden'
    }}>
    <Heading type="p-300-150-a" as="h4" variant="bold">
      {message.headline}
    </Heading>
    <Paragraph
      type="p-300-150-a"
      sx={{
        '& > a': {
          textDecoration: 'underline',
          color: 'purple'
        }
      }}
      dangerouslySetInnerHTML={{ __html: message.text }}
    />
  </Box>
);

const GridSpacer = ({ position, row }) => {
  const gridColumns = {
    gridColumn: [
      '1/13',
      '1/span 10',
      '1/span 14',
      '1/span 9',
      '1/span 7',
      '1/span 7'
    ]
  };

  const styles = [
    {
      ...gridColumns,
      mt: ['12.6%', '10.5%', '10%', '10.2%', '15%', '13.6%']
    },
    {
      ...gridColumns,
      mt: ['12.6%', '12.5%', '10%', '10.2%', '15%', '13.6%']
    }
  ];

  return <Spacer sx={{ gridRow: row, ...styles[position] }} />;
};
