import React from 'react';

import { Box, Heading, Paragraph } from '@thepuzzlers/pieces';

export const TextBlock = ({ data: { title, text } }) => {
  return (
    <Box
      className="footer__text-block"
      sx={{
        gridColumn: [
          '1/span 9',
          '7/13',
          '3/span 9',
          '1/span 9',
          '2/span 7',
          '3/span 6'
        ],
        gridRow: [5, 3, 5, 6, 6, 6]
      }}>
      <Headline title={title} />
      <Text text={text} />
    </Box>
  );
};

const Headline = ({ title }) => (
  <Heading
    as="h3"
    type="h3-500-125-g"
    dangerouslySetInnerHTML={{
      __html: title
    }}
  />
);

const Text = ({ text }) => (
  <Paragraph
    type="p-300-150-a"
    variant="medium"
    sx={{ mt: ['5%', '4.4%', '4%', '4%', '4%', '5.5%'] }}>
    {text}
  </Paragraph>
);
