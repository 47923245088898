import { useStaticQuery, graphql } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export function useFooterData() {
  const { locale } = usePageContext();
  const data = useStaticQuery(graphql`
    query {
      en: footerDataJson {
        ctaCard {
          headline
          button
          appScreens {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        textblock {
          title
          text
        }
        linkblock {
          title
          links {
            name
            to
            icon {
              publicURL
            }
          }
        }
        contact {
          title
          text
          socials {
            name
            icon {
              publicURL
            }
            href
          }
        }
        legal {
          links {
            name
            to
          }
          copyright
        }
      }
      de: deFooterDataJson {
        ctaCard {
          headline
          button
          appScreens {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        textblock {
          title
          text
        }
        linkblock {
          title
          links {
            name
            to
            icon {
              publicURL
            }
          }
        }
        contact {
          title
          text
          socials {
            name
            icon {
              publicURL
            }
            href
          }
        }
        legal {
          links {
            name
            to
          }
          copyright
        }
      }
    }
  `);

  return data[locale];
}
