import React from 'react';

// Assets
import checkListBook from 'assets/overlay/check-list-book.svg';

// External Components
import { GridWrapper } from '@thepuzzlers/pieces';

// Local Components
import { Vector, Spacer } from 'components';

// Self components
import { Headline, SubHeadline, SocialMediaWrapper, EmailLink } from '.';

// Animations
import { revealDecoration } from '../animations';

export const InfoWrapper = ({
  data: { headline, socialLinks, subHeadline }
}) => {
  return (
    <GridWrapper
      className="overlay__info-wrapper"
      variant="inside.autoColumns"
      sx={{
        alignSelf: 'start',
        gridColumn: [
          '1/13',
          '2/span 10',
          '5/span 16',
          '1/span 10',
          '4/span 8',
          '4/span 8'
        ],
        gridRow: [3, 3, 3, 2, 3, 3],
        position: [
          'relative',
          'relative',
          'relative',
          'sticky',
          'relative',
          'relative'
        ],
        top: [null, null, null, '8%', 'unset', 'unset']
      }}>
      <Headline headline={headline} />
      <GridSpacer position={0} />
      {/* <SocialMediaWrapper socialLinks={socialLinks} /> */}
      <EmailLink link={socialLinks[0]} />
      <GridSpacer position={1} />
      <SubHeadline subHeadline={subHeadline} />
      <GridSpacer position={2} />
      <OverlayDecoration />
    </GridWrapper>
  );
};

const OverlayDecoration = () => (
  <Vector
    // Animation values
    animate="open"
    initial="closed"
    exit="closed"
    variants={revealDecoration}
    svg={checkListBook}
    sx={{
      gridRow: ['3/span 3', '3/span 3', '1/span 2', '1/span 2', 3, 2],
      gridColumn: [
        '10/span 3',
        '9/span 2',
        '13/span 4',
        '8/span 3',
        '6/span 3',
        '6/span 3'
      ],
      alignSelf: ['center', 'center', 'end', 'end', 'start', 'start'],
      justifySelf: 'end',
      width: ['100%', '100%', '100%', '84%', '100%', '100%']
    }}
  />
);

const GridSpacer = ({ position }) => {
  const spacersGridColumns = {
    gridColumn: ['1/13', '1/span 10', '1/span 16', '1/span 10', '1/span 8']
  };

  const styles = [
    {
      gridRow: [2, 2, 2, 2, 2, 3],
      mt: ['16.93%', '11%', '12.4%', '12.2%', '10.8%', '17.3%'],
      ...spacersGridColumns
    },
    {
      gridRow: [4, 4, 4, 3, 4, 5],
      mt: ['5.02%', '4.4%', '4.5%', '12%', '24.5%', '5.8%'],
      ...spacersGridColumns
    },
    {
      display: ['none', 'none', 'none', 'block', 'block', 'none'],
      gridRow: [null, null, null, 5, 6, null],
      mt: [null, null, null, '7.5%', '10.8%', null],
      ...spacersGridColumns
    }
  ];

  return <Spacer sx={{ ...styles[position] }} />;
};
