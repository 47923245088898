import React from 'react';
import { motion } from 'framer-motion';

import { Box, Button } from '@thepuzzlers/pieces';

export const ButtonPrimary = ({ variant, handleClick, children, sx, ...props }) => {
  return (
    <Button
      variant={variant}
      onClick={handleClick}
      whileHover="hover"
      initial="initial"
      sx={{
        overflow: 'hidden',
        position: 'relative',
        width: 'min-content',
        ...sx
      }}
      {...props}>
      {children}
      <Line />
    </Button>
  );
};

const Line = () => (
  <Box
    as="span"
    sx={{
      display: 'block',
      position: 'absolute',
      // circle line
      // top: '-1%',
      // left: '-1%',
      // width: '30%'
      // -------------
      // wavy line
      top: '10%',
      right: '-1%',
      width: '80%',
      transform: 'rotate(20deg) scaleX(-1)'
    }}>
    {/* circle line */}
    {/* <svg viewBox="0 0 218 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path
      variants={variants}
        d="M1 96.7095C73 119.181 217 131.3 217 0"
        stroke="#5C20E9"
        strokeWidth="5"
      />
    </svg> */}

    {/* wavy line */}
    <svg
      viewBox="0 0 305 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none">
      <motion.path
        variants={drawPath}
        d="M304 7.03342C291.736 31.3467 249.263 68.8279 177.486 24.2464C87.764 -31.4805 -9.48835 24.8919 1.91514 69"
        stroke="#5C20E9"
        strokeWidth="3"
      />
    </svg>
  </Box>
);

// Draw line framer motion animation
const drawPath = {
  initial: {
    pathLength: 0
  },
  hover: {
    pathLength: 1,
    transition: {
      duration: 0.3,
      ease: 'linear'
    }
  }
};
