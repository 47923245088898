import React from 'react';

// External Components
import { Box, Image } from '@thepuzzlers/pieces';

// Animations
import { spinElement } from 'sections/animations';

export const FlowerOutlineVector = ({ color, img, wrapperSx, imgSx }) => {
  return (
    <Box sx={{ position: 'relative', ...wrapperSx }}>
      <Outline color={color} />
      <Image
        src={img}
        alt={null}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          ...imgSx
        }}
      />
    </Box>
  );
};

const Outline = ({ color }) => (
  <Box animate={spinElement.animate} sx={{ display: 'flex' }}>
    <svg
      overflow="visible"
      viewBox="0 0 162 162"
      height="100%"
      width="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M160 81C160 89.3869 149.809 95.985 147.451 103.551C145.093 111.117 149.472 122.993 144.896 129.503C140.32 136.013 128.361 135.369 122.044 140.031C115.727 144.694 112.92 156.951 105.424 159.503C97.9286 162.054 89.0291 153.961 81 153.961C72.9708 153.961 63.8188 161.966 56.5757 159.503C49.3326 157.039 46.1883 144.84 39.9559 140.031C33.7235 135.222 21.6798 136.101 17.0757 129.503C12.4716 122.905 17.0757 111.41 14.549 103.551C12.0224 95.6918 2 89.3869 2 81C2 72.6131 12.1908 66.015 14.549 58.4491C16.9073 50.8833 12.5277 39.0361 17.0757 32.4967C21.6237 25.9572 33.6393 26.6316 39.9559 21.969C46.2725 17.3063 49.08 5.04866 56.5757 2.49739C64.0714 -0.0538715 72.9708 8.03966 81 8.03966C89.0291 8.03966 98.1812 0.0341032 105.424 2.49739C112.667 4.96068 115.812 17.1597 122.044 21.969C128.276 26.7783 140.32 25.8986 144.896 32.4967C149.472 39.0948 144.896 50.6194 147.451 58.4491C150.006 66.2789 160 72.6131 160 81Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);
