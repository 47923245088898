import React from 'react';

// pieces
import { GridItem } from '@thepuzzlers/pieces';

/* ------------------------------------------------
----- This spacer is meant to be used when it ----- 
----- needs to occupy all columns in the grid ----- 
------------------------------------------------ */

export const FullGridSpacer = ({ sx }) => {
  return <GridItem className="full-width-grid-spacer" sx={{ ...sx }} />;
};
