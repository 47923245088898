import { gsap } from 'gsap/all';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin);

// Spin element infinitely with framer motion 
export const spinElement = {
  animate: {
    rotate: 360,
    transition: {
      repeat: Infinity,
      duration: 38,
      ease: 'linear'
    }
  }
};

// Fade in text on scroll
export const fadeInText = (element) => {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: element.current,
      start: 'top 80%'
    }
  });

  tl.fromTo(
    element.current,
    { autoAlpha: 0, y: 50 },
    { autoAlpha: 1, y: 0, duration: 0.6, ease: 'Power1.easeOut' }
  );

  const killAnimation = () => {
    tl.scrollTrigger.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};

// Draw lines
export const drawPaths = (element, start = 'top 80%') => {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: element.current,
      start: start
    }
  });

  tl.from(element.current, {
    drawSVG: 0,
    duration: 0.8,
    ease: 'Power1.easeOut'
  });

  const killAnimation = () => {
    tl.scrollTrigger.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};

// Framer motion animation to scale elements infinitely
export const scaleInAndOut = {
  opacity: 1,
  scale: [0.9, 1, 0.9],

  transition: {
    opacity: { duration: 0 },
    default: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 6,
      loop: Infinity
    }
  }
};
