export const forms = {
  input: {
    variant: 'typography.input.normal',
    bg: 'transparent',
    borderBottom: ({ colors }) => `2px solid ${colors.black}`,
    py: ['12px', '12px', '12px', '10px', '12px', '12px'],
    px: '0px',
    color: ({ colors }) => `${colors.black}`,
    '&::placeholder': {
      color: ({ colors }) => `${colors.black}`,
      opacity: 0.5
    },
    '&:focus': {
      outline: 'none',
      borderBottom: ({ colors }) => `2px solid ${colors.green}`
    }
  },
  label: {
    variant: 'typography.label.medium',
    color: 'black'
  }
};
