import React from 'react';

// External Components
import { Link, Box, Image } from '@thepuzzlers/pieces';

export const TextLinkiWithIcon = ({
  href,
  name,
  icon,
  sx,
  imageSx,
  textSx,
  ...props
}) => {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx
      }}
      {...props}>
      <Image src={icon} alt={null} sx={imageSx} />
      <Box as="span" sx={textSx}>
        {name}
      </Box>
    </Link>
  );
};
