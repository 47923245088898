import React from 'react';

// Local components
import { Vector } from 'components';

// Assets
import logo from 'assets/footer/logo.svg';

export const Logo = () => {
  return (
    <Vector
      className="footer__logo"
      svg={logo}
      alt="Boxenstop logo"
      sx={{
        alignSelf: ['start', 'end', 'start', 'start', 'start', 'start'],
        gridColumn: [
          '1/span 6',
          '1/span 4',
          '3/span 6',
          '1/span 5',
          '2/span 4',
          '3/span 4'
        ],
        gridRow: [3, 3, 3, '3/span 2', '3/span 2', '3/span 2'],
        width: ['95%', '100%', '95%', '100%', '100%', '84%']
      }}
    />
  );
};
