import React from 'react';
import { Box } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { Vector } from './Vector';

export const NavLink = ({
  to,
  label,
  icon,
  index,
  variant,
  sx,
  iconStyles
}) => {
  return (
    <Box
      className="nav-link"
      sx={{
        position: 'relative',
        minWidth: 'fit-content',
        '&:hover': {
          '& > div': {
            transform: 'scale(1)'
          }
        },
        ...sx
      }}>
      <NavigationLink
        to={to}
        variant={variant}
        // sx={{
        //   '&.active': {
        //     '& + div': {
        //       transform: 'scale(1)'
        //     }
        //   }
        // }}
      >
        {label}
      </NavigationLink>
      <Vector
        svg={icon}
        alt={null}
        sx={{
          position: 'absolute',
          ...iconStyles[index],

          //animation Value
          transform: 'scale(0)',
          transition: 'all 0.3s ease'
        }}
      />
    </Box>
  );
};
