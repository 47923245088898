import React from 'react';

// Pieces components
import { Box } from '@thepuzzlers/pieces';

// Animations
import { drawPaths } from 'sections/animations';

/* ----------------------------------------------------
Values that must be added for drawSvg animation to work:
    In svg tag:
        preserveAspectRatio="none"
    In path tag: 
        ref={pathRef}
---------------------------------------------------- */

/* ---------------- Feature Section ---------------- */

export const FeatureTopLine = () => {
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = drawPaths(pathRef);

    return killAnimation;
  }, []);

  return (
    <Box
      className="feature__top-decoration-line"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        transform: [
          'translate(0%, -25% )',
          'translate(-1%, -23% )',
          'translate(-1%, -21% )',
          'translate(-2%, -21% )',
          'translate(-4%, -24% )',
          'translate(-7%, -27% )'
        ],
        width: ['63%', '47%', '34%', '36%', '28%', '23%']
      }}>
      <svg
        viewBox="0 0 218 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          ref={pathRef}
          d="M1 96.7095C73 119.181 217 131.3 217 0"
          stroke="#5C20E9"
          strokeWidth="1.60595"
        />
      </svg>
    </Box>
  );
};

export const FeatureBottomLine = () => {
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = drawPaths(pathRef);

    return killAnimation;
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transform: [
          'translate(0%, 5% )',
          'translate(1%, 8% )',
          'translate(0%, 4% )',
          'translate(2%, 2% )',
          'translate(2%, 21% )',
          'translate(2%, 21% )'
        ],
        width: ['88%', '68%', '50%', '50%', '46%', '40%']
      }}>
      <svg
        viewBox="0 0 305 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          ref={pathRef}
          d="M304 7.03342C291.736 31.3467 249.263 68.8279 177.486 24.2464C87.764 -31.4805 -9.48835 24.8919 1.91514 69"
          stroke="#5C20E9"
          strokeWidth="1.24992"
        />
      </svg>
    </Box>
  );
};

/* ------------- Opportunities Section ------------- */

export const OpportunitiesCircleLine = () => {
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = drawPaths(pathRef, 'top bottom');

    return killAnimation;
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: ['-1%', '-5%', '-5%', 'unset', 'unset', 'unset'],
        right: ['-4%', 0, '-8%', 'unset', 'unset', 'unset'],
        left: [null, null, null, '-8%', '-5%', '-4%'],
        bottom: [null, null, null, '-2%', '-1%', '-2%'],
        transform: [
          'scaleX(-1)rotate(80deg)',
          'scaleX(-1)rotate(90deg)',
          'scaleX(-1)rotate(90deg)',
          'scaleX(-1)rotate(-100deg)',
          'scaleX(-1)rotate(-100deg)',
          'scaleX(-1)rotate(-100deg)'
        ],
        width: ['40%', '36%', '28%', '45%', '35%', '33%']
      }}>
      <svg
        viewBox="0 0 134 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          ref={pathRef}
          d="M115.439 151C143.425 101 158.517 0.999991 -5.00001 0.999999"
          stroke="#5C20E9"
          strokeWidth="2"
        />
      </svg>
    </Box>
  );
};

export const OpportunitieWavyLine = () => {
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = drawPaths(pathRef, 'top 60%');

    return killAnimation;
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: ['-1%', '-1%', '-1%', '3%', '3%', '2%'],
        left: ['-8%', '-3%', '-10%', '-2%', '-3%', '-2%'],
        transform: [
          'unset',
          'unset',
          'unset',
          'rotate(-5deg)',
          'rotate(-10deg)',
          'rotate(-10deg)'
        ],
        width: ['96%', '61%', '51%', '95%', '77%', '70%']
      }}>
      <svg
        viewBox="0 0 417 170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          ref={pathRef}
          d="M-43.4984 168.928C-34.3727 125.122 17.1002 47.5679 149.987 87.8015C316.095 138.093 446.481 7.25793 409.623 -57.901"
          stroke="#5C20E9"
          strokeWidth="2"
        />
      </svg>
    </Box>
  );
};

/* -------------------- Footer -------------------- */

export const FooterWavyLine = () => {
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = drawPaths(pathRef, 'bottom 80%');

    return killAnimation;
  }, []);

  return (
    <Box
      className="cta-card__line"
      sx={{
        position: 'absolute',
        transform: [
          'rotate(-10deg)',
          'rotate(-10deg)',
          'rotate(-5deg)',
          'unset',
          'unset',
          'unset'
        ],
        top: ['-52%', '-51%', '-46%', '-43%', '-4%', '-13%'],
        right: ['-13%', '-12%', '-17%', '-18%', '-17%', '-28%'],
        width: ['100%', '100%', '100%', '102%', '100%', '100%'],
        zIndex: 1
      }}>
      <svg
        viewBox="0 0 326 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          ref={pathRef}
          d="M1.54935 179.577C8.74457 145.037 49.329 83.889 154.105 115.612C285.075 155.265 387.879 52.1061 358.818 0.730798"
          stroke="#5C20E9"
          strokeWidth="1.6"
        />
      </svg>
    </Box>
  );
};

export const FooterCircleLine = () => {
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = drawPaths(pathRef, 'top 70%');

    return killAnimation;
  }, []);

  return (
    <Box
      className="cta-card__background__line"
      sx={{
        position: 'absolute',
        top: '-1%',
        left: ['-2%', '-3%', '-2%', '-1%', '-1%', '-1%'],
        width: ['56%', '35%', '42%', '29%', '21%', '21%'],
        zIndex: 1
      }}>
      <svg
        viewBox="0 0 161 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          ref={pathRef}
          d="M-7.99414 65.3472C47.9626 82.8553 159.876 92.2971 159.876 -10"
          stroke="#5C20E9"
          strokeWidth="1.59877"
        />
      </svg>
    </Box>
  );
};

export const FooterBlobLine = () => {
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = drawPaths(pathRef, 'top 70%');

    return killAnimation;
  }, [pathRef]);

  return (
    <Box
      className="cta-card__blob-line"
      sx={{
        display: ['none', 'none', 'block', 'block', 'block', 'block'],
        position: 'absolute',
        bottom: [null, null, '-2%', '-2%', '-2%', '-2%'],
        left: [null, null, '-22%', '-18%', '-57%', '-69%'],
        width: [null, null, '83%', '53%', '66%', '79%'],
        zIndex: 1
      }}>
      <svg
        viewBox="0 0 266 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          ref={pathRef}
          d="M229.5 170.53C286.5 123.53 276.8 18.2303 174 3.03026C136.8 -1.76974 104.167 11.3636 92.5 18.5303C77.0486 26.3892 51.2922 46.5331 48.1062 73.5C47.3057 80.275 47 88.5 50.5 95.0302C52.98 99.6574 57.7 106.33 64.5 113.53C71.3 120.73 69.6667 129.53 68 133.03C61.5714 146.53 46 154.03 1 170.53"
          stroke="#5C20E9"
          strokeWidth="2"
        />
      </svg>
    </Box>
  );
};
