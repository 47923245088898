import React from 'react';

// External components
import {
  GridItem,
  GridWrapper,
  Heading,
  Button,
  FlexWrapper
} from '@thepuzzlers/pieces';

// Local components
import {
  FullGridSpacer,
  Vector,
  ImageContainer,
  FooterWavyLine,
  FooterCircleLine,
  FooterBlobLine
} from 'components';
import { JoinNowOverlay, useJoinNowOverlay } from 'sections/overlay';

// Assets
import arrow from 'assets/footer/arrow.svg';

// Animations
import {
  // slideUpAppScreens,
  ctaCardAnimation,
  arrowMotion
} from '../animations';

export const CTACard = ({ data: { headline, button, appScreens } }) => {
  const [overlayProps, overlayControl] = useJoinNowOverlay();

  const ctaCardRef = React.useRef();
  const timelineRef = React.useRef();

  React.useEffect(() => {
    const animation = ctaCardAnimation(timelineRef, ctaCardRef);

    return () => {
      animation.kill();
    };
  }, []);

  return (
    // Markup
    <CTACardWrapper ref={ctaCardRef}>
      <Spacer position={0} />
      <Headline headline={headline} />
      <Spacer position={1} />
      <ButtonWrapper
        text={button}
        handleClick={overlayControl.handleOpenOverlay}
      />
      <Spacer position={2} />
      <AppScreens data={appScreens} />
      <Background />
      <JoinNowOverlay {...overlayProps} />
    </CTACardWrapper>
  );
};

// Elements
const CTACardWrapper = React.forwardRef(({ children }, ref) => (
  <GridItem
    ref={ref}
    className="footer__cta-card"
    sx={{
      gridColumn: ['1/13', '1/13', '2/span 22', '1/25', '1/25', '1/25'],
      gridRow: 1,
      overflow: 'hidden'
    }}>
    <GridWrapper variant="inside.autoColumns">{children}</GridWrapper>
  </GridItem>
));

const Headline = ({ headline }) => (
  <Heading
    as="h2"
    type="h2-300-125-e"
    variant="light"
    dangerouslySetInnerHTML={{
      __html: headline
    }}
    sx={{
      color: 'white',
      gridColumn: [
        '2/span 10',
        '2/span 10',
        '3/span 18',
        '3/span 14',
        '2/span 11',
        '3/span 11'
      ],
      gridRow: 2,
      position: 'relative',
      zIndex: 1,
      '& > span': {
        ' > span.green-text': {
          color: 'green'
        }
      }
    }}
  />
);

const ButtonWrapper = ({ text, handleClick }) => (
  <FlexWrapper
    sx={{
      gridColumn: [
        '2 / 13',
        '2 / 13',
        '3 / span 12',
        '3 / span 10',
        '2 / span 8',
        '3 / span 7'
      ],
      gridRow: 4,
      zIndex: 1,
      alignItems: 'center'
    }}>
    <Button onClick={handleClick} variant="secondary">
      {text}
    </Button>
    <ArrowLeft />
  </FlexWrapper>
);

const ArrowLeft = () => {
  return (
    <Vector
      // Framer motion animation variant
      animate="animate"
      variants={arrowMotion}
      className="cta-card__arrow-left"
      svg={arrow}
      alt={null}
      sx={{
        display: 'inline-block',
        ml: ['7%'],
        width: ['33%', '25%', '37%', '36%', '36%', '39%']
      }}
    />
  );
};

const AppScreens = ({ data }) => (
  <GridItem
    className="cta-card__app-screens"
    sx={{
      alignSelf: 'end',
      gridColumn: [
        '2/span 10',
        '2/span 10',
        '8/span 13',
        '11/span 12',
        '15/span 9',
        '15/span 8'
      ],
      gridRow: [6, 6, 6, '4/span 2', '2/span 4', '2/span 4'],
      zIndex: 1
    }}>
    <AppScreensWrapper data={data} />
    <FooterWavyLine />
    <FooterBlobLine />
  </GridItem>
);

const AppScreensWrapper = ({ data }) => {
  const styles = [
    {
      mb: '-34%',
      width: '48%'
    },
    {
      mb: '-50%',
      ml: '-10%',
      width: '62%'
    }
  ];

  return (
    <FlexWrapper
      className="cta-card__app-screens-wrapper"
      sx={{
        alignItems: 'flex-end',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2
      }}>
      {data.map((item, index) => {
        return (
          <ImageContainer
            key={item.alt}
            image={item.image}
            imageAlt={item.alt}
            sx={{ ...styles[index] }}
          />
        );
      })}
    </FlexWrapper>
  );
};

const Background = () => (
  <GridItem
    className="cta-card__background"
    sx={{
      bg: 'purple',
      borderRadius: ['20px', '24px', '40px', '20px', '40px', '48px'],
      gridColumn: ['1/13', '1/13', '1/span 22', '1/25', '1/25', '1/25'],
      gridRow: ['1/span 6', '1/span 6', '1/span 6', '1/span 5'],
      zIndex: 0
    }}>
    <FooterCircleLine />
  </GridItem>
);

// Spacer

const Spacer = ({ position }) => {
  const styles = [
    {
      gridRow: 1,
      mt: ['8.47%', '7%', '6.5%', '6.5%', '4.3%', '6.6%']
    },
    {
      gridRow: 3,
      mt: ['11.29%', '8.7%', '5.2%', '5%', '7%', '5.9%']
    },
    {
      display: ['block', 'block', 'block', 'none', 'block', 'block'],
      gridRow: 5,
      mt: ['26.34%', '17.8%', '3.8%', null, '4.9%', '6%']
    }
  ];

  return (
    <FullGridSpacer
      sx={{
        gridColumn: ['1/13', '1/13', '1/span 22', '1/25', '1/25', '1/25'],
        ...styles[position]
      }}
    />
  );
};
