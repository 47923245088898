import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useOverlayData = () => {
  const { locale } = usePageContext();
  const data = useStaticQuery(graphql`
    query {
      en: overlayDataJson {
        headline
        subHeadline
        socialLinks {
          name
          href
          icon {
            publicURL
          }
        }
        form {
          inputs {
            type
            name
            placeholder
          }
          submitButtonText
        }
        agreementText
        messages {
          success {
            headline
            text
          }
          error {
            headline
            text
          }
        }
      }
      de: deOverlayDataJson {
        headline
        subHeadline
        socialLinks {
          name
          href
          icon {
            publicURL
          }
        }
        form {
          inputs {
            type
            name
            placeholder
          }
          submitButtonText
        }
        agreementText
        messages {
          success {
            headline
            text
          }
          error {
            headline
            text
          }
        }
      }
    }
  `);
  return data[locale];
};
