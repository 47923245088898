import React from 'react';

// Local Components
import { TextLinkiWithIcon } from 'components';

// Animations
import { revealSocialItem } from '../animations';

// Main Components
export const EmailLink = ({ link }) => {
  return (
    <TextLinkiWithIcon
      href={link.href}
      name={link.name}
      icon={link.icon.publicURL}
      variant="typography.p-300-150-a.medium"
      sx={{
        gridRow: [3, 3, 3, 4, 5, 4],
        gridColumn: [
          '1/span 10',
          '1/span 10',
          '1/span 10',
          '1/span 10',
          '1/span 11',
          '1/span 9'
        ],
        justifyContent: 'flex-start'
      }}
      imageSx={{
        width: ['9.06%', '5.60%', '8.35%', '8.50%', '5.29%', '5.11%']
      }}
      textSx={{
        ml: ['4.53%', '2.80%', '4.18%', '4.25%', '2.65%', '2.55%']
      }}
      // animation value
      animate="open"
      initial="closed"
      exit="closed"
      variants={revealSocialItem}
    />
  );
};
