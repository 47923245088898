import React from 'react';

// Pieces components
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { Image } from '@thepuzzlers/pieces';

// Assets
import logoSvg from 'assets/svg/logo.svg';
import fullLogoSvg from 'assets/svg/logo-full.svg';

export const Logo = () => {
  return (
    <NavigationLink
      variant="navigation"
      to="/"
      sx={{
        gridColumn: [
          '1/span 2',
          '1/span 2',
          '1/span 8',
          '1/span 6',
          '1/span 6',
          '1/span 5'
        ],
        width: ['88%', '64%', '90%', '93%', '83%', '87%'],
        // Animation value
        visibility: 'hidden'
      }}>
      <Image
        src={logoSvg}
        alt="Boxenstop logo"
        sx={{ display: ['block', 'block', 'none', 'none', 'none', 'none'] }}
      />
      <Image
        src={fullLogoSvg}
        alt="Boxenstop logo"
        sx={{ display: ['none', 'none', 'block', 'block', 'block', 'block'] }}
      />
    </NavigationLink>
  );
};
