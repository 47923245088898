import React from 'react';

// External components
import { Section } from '@thepuzzlers/pieces';

// Local components
import { FullGridSpacer } from 'components';

// Data
import { useFooterData } from './useFooterData';

// Self components
import {
  CTACard,
  Logo,
  TextBlock,
  LinkBlock,
  ContactBlock,
  LegalLinks,
  Copyright
} from './components';

// Main component
export const Footer = () => {
  const { ctaCard, textblock, linkblock, contact, legal } = useFooterData();

  //Markup
  return (
    <Section as="footer" sx={{ bg: 'white' }}>
      <CTACard data={ctaCard} />
      <Spacer position={0} />
      <Logo />
      <Spacer position={1} />
      <TextBlock data={textblock} />
      <Spacer position={2} />
      <LinkBlock data={linkblock} />
      <ContactBlock data={contact} />
      <Spacer position={3} />
      <LegalLinks data={legal} />
      <Spacer position={4} />
      <Copyright data={legal} />
      <Spacer position={5} />
    </Section>
  );
};

// Inner grid spacer

const Spacer = ({ position }) => {
  const styles = [
    {
      gridRow: 2,
      mt: ['14.74%', '19.5%', '8.5%', '6.4%', '7%', '4.6%']
    },
    {
      gridRow: [4, 4, 4, 3, 3, 3],
      mt: ['7.52%', '10.2%', '3.1%', '1.6%', '1.5%', '1.2%']
    },
    {
      gridRow: [6, 6, 6, 5, 5, 5],
      mt: ['17.24%', '12.2%', '9.33%', '3%', '2.9%', '1.2%']
    },
    {
      gridRow: [8, 8, 8, 7, 7, 7],
      mt: ['14.74%', '13.3%', '4.7%', '5.2%', '5.1%', '4.4%']
    },
    {
      display: ['block', 'none', 'block', 'block', 'block', 'block'],
      gridRow: [10, 10, 10, 9, 9, 9],
      mt: ['7.52%', null, '3.4%', '6.9%', '11%', '6.6%']
    },
    {
      display: ['block', 'none', 'block', 'none', 'none'],
      gridRow: 12,
      mt: ['10.97%', null, '12%', null]
    }
  ];

  return <FullGridSpacer sx={{ ...styles[position] }} />;
};
