import React from 'react';

//Assets
import closeIcon from 'assets/overlay/close.svg';

//External Components
import { Image, Button } from '@thepuzzlers/pieces';

export const CloseButton = ({ onClick }) => {
  return (
    <Button
      variant="emptyBase"
      onClick={onClick}
      sx={{
        alignSelf: 'start',
        gridRow: 2,
        display: 'flex',
        alignItems: 'center',
        justifySelf: 'end',
        gridColumn: ['12/13', '11/span 1', '20/span 2', '24/25', '21/span 1'],
        width: ['100%', '60%', '45%', '100%', '56%']
      }}>
      <Image
        src={closeIcon}
        alt={null}
        sx={{ width: '100%', height: '100%' }}
      />
    </Button>
  );
};
