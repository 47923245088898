import React from 'react';

// External components
import { Box, Paragraph } from '@thepuzzlers/pieces';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';
import { NavLink } from 'components';

export const LinkBlock = ({ data: { title, links } }) => {
  return (
    <Box
      className="footer__link-block"
      sx={{
        alignSelf: 'start',
        gridColumn: [
          '1/span 5',
          '1/span 3',
          '3/span 4',
          '12/span 4',
          '12/span 3',
          '12/span 3'
        ],
        gridRow: [7, 5, '7/span 5', '4/span 3', '4/span 3', '4/span 3']
      }}>
      <Headline title={title} />
      <LinksWrapper links={links} />
    </Box>
  );
};

const Headline = ({ title }) => (
  <Paragraph type="p-600-135-c" variant="bold">
    {title}
  </Paragraph>
);

const LinksWrapper = ({ links }) => {
  // Check which language switch is active
  const { locale } = usePageContext();
  const isInEnglish = locale === 'en';
  const iconStyles = [
    {
      top: isInEnglish ? '-26%' : '-30%',
      left: '-12%',
      width: isInEnglish ? '23%' : '20%'
    },
    {
      right: isInEnglish ? '-13%' : '-12%',
      bottom: '-38%',
      width: isInEnglish ? '14%' : '13%'
    },
    { top: '-24%', right: '-53%', width: '52%' }
  ];
  return (
    <Box
      sx={{
        mt: ['12%', '15%', '11.6%', '16%', '16.5%', '17%'],
        '& > .nav-link:nth-of-type(n+2)': {
          mt: ['9.2%', '15%', '11.6%', '16%', '14.8%', '11%']
        }
      }}>
      {links.map((link, index) => {
        return (
          <NavLink
            key={`footer__nav-link-${index}`}
            to={link.to}
            label={link.name}
            icon={link.icon.publicURL}
            index={index}
            variant="typography.p-300-150-a.medium"
            sx={{ width: 'max-content' }}
            iconStyles={iconStyles}
          />
        );
      })}
    </Box>
  );
};
