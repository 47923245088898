import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box } from '@thepuzzlers/pieces';

export const ImageContainer = ({
  className,
  image,
  imageAlt,
  sx,
  imageRef = null,
  ...props
}) => {
  return (
    <Box
      className={`imageContainer ${className}`}
      ref={imageRef}
      sx={{ position: 'relative', ...sx }}>
      <GatsbyImage
        image={getImage(image)}
        alt={imageAlt}
        style={{ maxHeight: '100%', width: '100%' }}
        {...props}
      />
    </Box>
  );
};
