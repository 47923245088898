import React from 'react';

// Components
import { ButtonPrimary } from 'components';

export const NavButton = ({ button, onClick }) => {
  return (
     <ButtonPrimary
      variant="primary"
      handleClick={onClick}
      sx={{
        gridColumn: [
          '5/span 5',
          '7/span 4',
          '17/span 6',
          '17/span 5',
          '19/span 4',
          '19/span 4'
        ],
        gridRow: 1,
        justifySelf: ['end', 'end', 'center', 'end', 'end', 'end'],
        width: 'min-content',
        // Animation values
        visibility: 'hidden'
      }}>
      {button}
    </ButtonPrimary>
  );
};
