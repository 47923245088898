import React from 'react';

// External components
import { Box, Link, Paragraph, Image } from '@thepuzzlers/pieces';

//Local Components
import { TextLinkiWithIcon } from 'components';

//Main Components
export const ContactBlock = ({ data: { title, text, socials } }) => {
  //Markup
  return (
    <Box
      className="footer__contact-block"
      sx={{
        gridColumn: [
          '6/13',
          '7/span 5',
          '14/span 9',
          '18/25',
          '18/span 6',
          '17/span 5'
        ],
        gridRow: [7, 5, '5/span 3', '4/span 3', '4/span 3', '4/span 3']
      }}>
      <Headline title={title} />
      <Text text={text} />
      <EmailLink email={socials[0]} />
      {/* <SocialWrapper socials={socials} /> */}
    </Box>
  );
};

const Headline = ({ title }) => (
  <Paragraph type="p-600-135-c" variant="bold">
    {title}
  </Paragraph>
);

const Text = ({ text }) => (
  <Paragraph
    type="p-300-150-a"
    variant="medium"
    dangerouslySetInnerHTML={{
      __html: text
    }}
    sx={{
      mt: ['9%', '8.9%', '4.2%', '9%', '8%', '9.8%'],
      '& > br': {
        display: ['block', 'block', 'block', 'none', 'none', 'none']
      }
    }}
  />
);

const EmailLink = ({ email }) => {
  return (
    <TextLinkiWithIcon
      href={email.href}
      name={email.name}
      icon={email.icon.publicURL}
      variant="typography.p-300-150-a.medium"
      sx={{
        mt: ['17.4%', '17.2%', '41.68%', '17.5%', '14.6%', '15.6%']
      }}
      imageSx={{
        width: ['13.04%', '11.47%', '9.33%', '12.29%', '9.85%', '9.45%']
      }}
      textSx={{
        ml: ['6.52%', '5.73%', '6.22%', '6.15%', '4.92%', '4.72%']
      }}
    />
  );
};

// const SocialWrapper = ({ socials }) => (
//   <FlexWrapper
//     sx={{
//       justifyContent: 'space-between',
//       mt: ['17.4%', '17.2%', '58.2%', '17.5%', '14.6%', '15.6%'],
//       width: ['65%', '51%', '40%', '52%', '43%', '41%']
//     }}>
//     {socials.map((item) => {
//       return (
//         <Link
//           key={item.href}
//           href={item.href}
//           target="_blank"
//           rel="noopener noreferrer"
//           sx={{ width: ['20%', '24%', '23%', '22%', '23%', '23%'] }}>
//           <Image
//             src={item.icon.publicURL}
//             alt={`${item.name} icon`}
//             sx={{ display: 'flex' }}
//           />
//         </Link>
//       );
//     })}
//   </FlexWrapper>
// );
