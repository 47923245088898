import React from 'react';

// External Components
import { LanguageSwitchLink } from 'gatsby-theme-thepuzzlers-intl';
import { Box, FlexWrapper } from '@thepuzzlers/pieces';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const LanguageSwitch = ({ isNavAnimationComplete }) => {
  const { locale } = usePageContext();
  const wrapperRef = React.useRef();
  const underlineElementRef = React.useRef();
  const [indicatorOriginPosition, setIndicatorOriginPosition] = React.useState({
    offsetLeft: 0,
    offsetWidth: 0
  });

  const handleOnMouseEnter = (e) => {
    if (!underlineElementRef.current.style.transition) {
      underlineElementRef.current.style.transition = 'all 0.1s ease';
    }
    const { offsetLeft, offsetWidth } = e.target;
    underlineElementRef.current.style.left = offsetLeft + 'px';
    underlineElementRef.current.style.width = offsetWidth + 'px';
  };

  const handleOnMouseLeave = () => {
    const { offsetLeft, offsetWidth } = indicatorOriginPosition;
    underlineElementRef.current.style.left = offsetLeft + 'px';
    underlineElementRef.current.style.width = offsetWidth + 'px';
  };

  React.useEffect(() => {
    //set the initial position of the underline
    if (isNavAnimationComplete) {
      const { offsetLeft, offsetWidth } =
        wrapperRef.current.querySelector('.selected');
      underlineElementRef.current.style.left = offsetLeft + 'px';
      underlineElementRef.current.style.width = offsetWidth + 'px';
      setIndicatorOriginPosition({ offsetLeft, offsetWidth });
    }
  }, [locale, isNavAnimationComplete]);

  return (
    // Markup
    <SwitchWrapper ref={wrapperRef}>
      <Link
        isSelected={locale === 'en'}
        onMouseEnter={handleOnMouseEnter}
        handleOnMouseLeave={handleOnMouseLeave}>
        EN
      </Link>
      <Link
        language="de"
        isSelected={locale === 'de'}
        sx={{
          ml: ['15.80%', '15.39%', '15.49%', '15.15%', '15.81%', '13.03%']
        }}
        onMouseEnter={handleOnMouseEnter}
        handleOnMouseLeave={handleOnMouseLeave}>
        DE
      </Link>
      <SlidingUnderline ref={underlineElementRef} />
    </SwitchWrapper>
  );
};

// Elements
const SwitchWrapper = React.forwardRef(({ children }, ref) => (
  <FlexWrapper
    ref={ref}
    sx={{
      gridColumn: ['10/13', '11/13', '22/25', '22/25', '23/25', '23/25'],
      position: 'relative',
      gridRow: 1,
      justifyContent: 'flex-end',
      '& > .not-selected': {
        opacity: 0.5
      },
      // Animation values
      visibility: 'hidden'
    }}>
    {children}
  </FlexWrapper>
));

const Link = ({
  children,
  isSelected = false,
  handleOnMouseEnter,
  handleOnMouseLeave,
  ...props
}) => (
  <LanguageSwitchLink
    variant="links.languageSwitchLink"
    className={`language-link ${isSelected ? 'selected' : 'not-selected'}`}
    onMouseEnter={handleOnMouseEnter}
    onMouseLeave={handleOnMouseLeave}
    {...props}>
    {children}
  </LanguageSwitchLink>
);

const SlidingUnderline = React.forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      className="language-switch__sliding-underline"
      sx={{
        position: 'absolute',
        bottom: '-23%',
        left: 0,
        width: 0,
        height: '2px',
        bg: 'purple',
        borderRadius: '2px'
      }}
    />
  );
});
