export const fontFamilies = {
  body: {
    normal: 'faible-regular, sans-serif',
    medium: 'faible-medium, sans-serif',
    bold: 'faible-bold, sans-serif'
  },
  primary: {
    normal: 'spoof-regular, sans-serif',
    bold: 'spoof-bold, sans-serif',
    light: 'spoof-light, sans-serif'
  }
};
