import { gsap } from 'gsap';

export const revealNav = (elements, setIsAnimationComplete) => {
  const [logo, links, button, languageSwitch] = [...elements.current.children];
  const navLinks = [...links.children];

  gsap.defaults({ duration: 0.6, ease: 'Power1.easeOut' });

  let tl = gsap.timeline({ onComplete: () => setIsAnimationComplete(true) });

  tl.fromTo(logo, { x: 50 }, { autoAlpha: 1, x: 0, delay: 1.5 })
    .fromTo(
      navLinks,
      { x: 50 },
      { autoAlpha: 1, x: 0, stagger: 0.3, delay: 0.8 }
    )
    .fromTo(
      button,
      { autoAlpha: 0, x: 50 },
      {
        autoAlpha: 1,
        x: 0,
        onComplete: () => {
          gsap.to(button, { clearProps: 'x' });
        }
      },
      3.4
    )
    .fromTo(languageSwitch, { x: 50 }, { autoAlpha: 1, x: 0 }, 3.6);

  const killAnimation = () => {
    tl.scrollTrigger.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};
