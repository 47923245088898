import React from 'react';

// External components
import { Box, FlexWrapper, Paragraph } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

export const LegalLinks = ({ data: { links } }) => {
  return (
    <FlexWrapper
      className="footer__legal-links"
      sx={{
        alignItems: 'baseline',
        gridColumn: [
          '1/span 4',
          '1/span 3',
          '14/span 4',
          '18/span 4',
          '18/span 3',
          '17/span 3'
        ],
        gridRow: [9, 7, 9, 8, 8, 8]
      }}>
      <Link link={links[0]} />
      <Dot />
      <Link link={links[1]} />
    </FlexWrapper>
  );
};

export const Copyright = ({ data: { copyright } }) => {
  return (
    <Text
      className="footer__copyright"
      text={copyright}
      sx={{
        gridColumn: [
          '1/span 8',
          '7/13',
          '14/span 8',
          '1/span 8',
          '2/span 6',
          '3/span 5'
        ],
        gridRow: [11, 7, 11, 8, 8, 8]
      }}
    />
  );
};

const Link = ({ link: { to, name } }) => (
  <NavigationLink
    to={to}
    variant="typography.p-300-100-b.medium"
    sx={{ color: 'secondary' }}>
    {name}
  </NavigationLink>
);

const Dot = () => <Text text="." sx={{ mx: '4%' }} />;

const Text = ({ text, sx, ...props }) => (
  <Paragraph
    type="p-300-100-b"
    variant="medium"
    sx={{ color: 'secondary', ...sx }}
    {...props}>
    {text}
  </Paragraph>
);
