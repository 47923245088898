import React from 'react';

// Pieces components
import { Box, fullWidthMinusMargins } from '@thepuzzlers/pieces';

export const GreenBackground = () => {
  return (
    <Box
      className="overlay__green-background"
      sx={{
        bg: 'green',
        borderRadius: [
          'unset',
          '32px 32px 0 0',
          '40px 40px 0 0',
          'unset',
          '40px 0 0 40px',
          '48px 0 0 48px'
        ],
        gridColumn: [
          '1/13',
          '1/13',
          '3/span 20',
          '1/span 11',
          '3/span 10',
          '3/span 10'
        ],
        gridRow: [
          '1/span 4',
          '1/span 4',
          '1/span 4',
          '1/span 3',
          '1/span 4',
          '1/span 4'
        ],
        mr: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0],
        ml: [fullWidthMinusMargins[0], 0, 0, fullWidthMinusMargins[3], 0, 0]
      }}
    />
  );
};

export const WhiteBackground = () => {
  return (
    <Box
      className="overlay__white-background"
      sx={{
        bg: 'background',
        borderRadius: [
          'unset',
          '0 0 32px 32px',
          '0 0 40px 40px',
          'unset',
          '0 40px 40px 0',
          '0 48px 48px 0'
        ],
        gridColumn: [
          '1/13',
          '1/13',
          '3/span 20',
          '11/25',
          '12/span 11',
          '12/span 11'
        ],
        gridRow: [
          '5/span 3',
          '5/span 3',
          '5/span 3',
          '1/span 3',
          '1/span 4',
          '1/span 4'
        ],
        mr: [fullWidthMinusMargins[0], 0, 0, fullWidthMinusMargins[3], 0, 0],
        ml: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0],
        zIndex: -1
      }}
    />
  );
};
