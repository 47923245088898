import React from 'react';

// Pieces components
import { FlexWrapper } from '@thepuzzlers/pieces';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';
import { NavLink } from 'components';

export const LinksWrapper = ({ links }) => {
  // Check which language switch is active
  const { locale } = usePageContext();
  const isInEnglish = locale === 'en';

  const iconStyles = [
    {
      top: '-34%',
      left: isInEnglish ? '-15%' : '-12%',
      width: isInEnglish ? '26%' : '20%'
    },
    {
      right: isInEnglish ? '-15%' : '-12%',
      bottom: '-58%',
      width: isInEnglish ? '16%' : '13%'
    },
    { top: '-32%', right: '-62%', width: '62%' }
  ];

  return (
    <FlexWrapper
      sx={{
        gridRow: 1,
        gridColumn: isInEnglish
          ? [null, null, null, null, '11 / span 9', '13 / span 7']
          : [null, null, null, null, '9/span 10', '11/span 8'],
        display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
        justifyContent: 'start',
        '& > div': {
          ml: [null, null, null, null, '14%', '17.58%'],
          '&:first-of-type': {
            ml: '0%'
          }
        }
      }}>
      {links.map((link, i) => (
        <NavLink
          key={`navlink-${i}`}
          to={link.to}
          label={link.title}
          icon={link.icon?.publicURL}
          index={i}
          variant="navigation"
          iconStyles={iconStyles}
          sx={{
            // Animation value
            visibility: 'hidden'
          }}
        />
      ))}
    </FlexWrapper>
  );
};
